import styles from "./step4.module.scss";
import { NavigationWarning } from "../components/Navigation/NavigationWarning";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { configurationState, currentStepState } from "../states";
import { useHistory } from "react-router-dom";
import CustomerDataSection from "../components/Recommendation/CustomerDataSection";
import SpecifiedInstrumentSection from "../components/Recommendation/SpecifiedInstrumentSection";
import RiskAssessmentSection from "../components/Recommendation/RiskAssessmentSection";
import WeighingProcessSection from "../components/Recommendation/WeighingProcessSection";
import RecommendedServiceSection from "../components/Recommendation/RecommendedServiceSection";
import RecommendationRoutineSection from "../components/Recommendation/RecommendationRoutineSection";
import { usePdf } from "../hooks/usePdfApi";
import { useAssessment } from "../hooks/useAssessmentApi";
import {
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CSpinner,
} from "@coreui/react";
import { AssessmentDataCalculatedDTO } from "../api";
import {
  ImpactMatch,
  ImpactWithNotApplicable,
  Matcher,
  ProbabilityMatch,
} from "../types/Configuration";
import { trackPageImpression } from "../services/googleTagManager";

const Step4: React.FC = () => {
  const history = useHistory();
  const [configuration] = useRecoilState(configurationState);
  const [assessment, setAssessment] = useState<AssessmentDataCalculatedDTO>(null);
  const [, setCurrentStep] = useRecoilState(currentStepState);
  const { downloadPdf } = usePdf();
  const { getAssessment } = useAssessment();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    if (!configuration.step3Data.isCompleted) {
      history.push("/step3");
      return;
    }
    const fetchAssessment = async () => {
      setIsLoading(true);
      try {
        const res = await getAssessment();
        setAssessment(res);
      } catch (error) {
        console.log("Error fetching assessment:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!assessment) {
      fetchAssessment();
      trackPageImpression(4);
    }

    setCurrentStep(4);
  }, [assessment]);

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };
  const fetchPdf = async () => {
    setIsLoading(true);

    try {
      const pdf = await downloadPdf();
    } catch (error) {
      console.error("Error downloading PDF:", error);
    } finally {
      setIsLoading(false);
      toggleShowModal();
    }
  };

  return (
    <>
      <CModal
        show={showModal}
        onClose={toggleShowModal}
        centered={true}
        size=""
        scrollable
      >
        <CModalHeader data-testid="help-modal-header" closeButton>
          <CModalTitle data-testid="help-modal-title">
            Do you want to download the PDF to your device?
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>
            <p>
              You can download the PDF now or review your information and
              download it later.
            </p>
          </div>
          <div className="d-flex flex-col align-items-between">
            <CButton
              onClick={fetchPdf}
              // style={}
              shape="square"
              active={true}
              color={showModal ? "primary" : ""}
              variant={"outline"}
              className={`border border-dark flex-grow-1 px-6 mr-6`}
              data-testid="help-button"
            >
              Yes
            </CButton>
            <CButton
              onClick={toggleShowModal}
              // style={}
              shape="square"
              color={showModal ? "dark " : ""}
              variant={"outline"}
              className={`border border-dark flex-grow-1 pr-6`}
              data-testid="help-button"
            >
              No
            </CButton>
          </div>
        </CModalBody>
      </CModal>
      <NavigationWarning missingFields={[]} />
      {assessment ? (
        <>
          <CustomerDataSection
            company={assessment.pageOneDTO.customerContactData.company}
            country={assessment.pageOneDTO.customerContactData.country}
            customerContact={assessment.pageOneDTO.customerContactData.email}
            streetAndAddressNumber={
              assessment.pageOneDTO.customerContactData.addressNumber
                ? assessment.pageOneDTO.customerContactData.street +
                  " " +
                  assessment.pageOneDTO.customerContactData.addressNumber
                : assessment.pageOneDTO.customerContactData.street
            }
            zipCodeAndCity={assessment.pageOneDTO.customerContactData.zipcode}
            department={assessment.pageOneDTO.customerContactData.industry}
          />
          <SpecifiedInstrumentSection
            model={
              assessment.pageOneDTO.instrumentConfigurations
                .instrumentConfigurations[0].model
            }
            manufacturer={
              assessment.pageOneDTO.instrumentConfigurations
                .instrumentConfigurations[0].manufacturer
            }
            type={
              assessment.pageOneDTO.instrumentConfigurations
                .instrumentConfigurations[0].type
            }
            isoCalFunction={
              assessment.pageOneDTO.instrumentConfigurations
                .instrumentConfigurations[0].isoCal
            }
            secondSubsection={
              assessment.pageOneDTO.instrumentConfigurations
                .instrumentConfigurations
            }
          />
          <RiskAssessmentSection
            subsections={[
              {
                name: "firstSection",
                values: [
                  {
                    value:
                      ImpactMatch[
                        Matcher[
                          assessment.pageOneDTO.riskAssessment.impactHealthScore
                        ]
                      ] +
                      "(" +
                      assessment.pageOneDTO.riskAssessment.impactHealthScore +
                      ")",
                  },
                  {
                    value:
                      ImpactMatch[
                        Matcher[
                          assessment.pageOneDTO.riskAssessment
                            .impactBusinessScore
                        ]
                      ] +
                      "(" +
                      assessment.pageOneDTO.riskAssessment.impactBusinessScore +
                      ")",
                  },
                  {
                    value:
                      assessment.pageOneDTO.riskAssessment.impactScore.toString(),
                    isValueBackgroundGray: true,
                  },
                ],
              },
              {
                name: "secondSection",
                values: [
                  {
                    value:
                      ProbabilityMatch[
                        Matcher[
                          assessment.pageOneDTO.riskAssessment
                            .probabilityOccurrenceScore
                        ]
                      ] +
                      "(" +
                      assessment.pageOneDTO.riskAssessment
                        .probabilityOccurrenceScore +
                      ")",
                  },
                  {
                    value:
                      ProbabilityMatch[
                        Matcher[
                          assessment.pageOneDTO.riskAssessment
                            .probabilityDetectionScore
                        ]
                      ] +
                      "(" +
                      assessment.pageOneDTO.riskAssessment
                        .probabilityDetectionScore +
                      ")",
                  },
                  {
                    value:
                      assessment.pageOneDTO.riskAssessment.probabilityScore.toString(),
                    isValueBackgroundGray: true,
                  },
                ],
              },
              {
                name: "thirdSection",
                values: [
                  {
                    value:
                      assessment.pageOneDTO.riskAssessment.riskAssessmentScore.toString(),
                    isValueBackgroundGray: true,
                  },
                ],
              },
            ]}
          />
          <WeighingProcessSection
            isRequiringEquipmentQualification={
              assessment.pageTwoDTO.equipmentQualificationNeeded
            }
            isRequiringUSPharmaCompliance={
              assessment.pageTwoDTO.requiresComplianceUSA
            }
            weight={assessment.pageTwoDTO.minWeight}
            isRequiringEUPharmaCompliance={
              assessment.pageTwoDTO.requiresComplianceEU
            }
            isBalanceUsedForTest={assessment.pageTwoDTO.isForTestPipettes}
            isRequiringFullMaintenance={
              assessment.pageTwoDTO.fullMaintenanceReqPos
            }
            requiredProcessAccuracy={
              assessment.pageTwoDTO.requiredProcessAccuracy
            }
            safetyFactor={assessment.pageTwoDTO.safetyFactor}
          />
          <RecommendedServiceSection
            config={assessment.pageTwoDTO}
            initialServices={assessment.pageThreeDTO}
            lifecycleServices={assessment.pageFourDTO}
          />
          <br/>
          <RecommendationRoutineSection
            subsections={[
              {
                name: "firstSection",
                values: [
                  {
                    value:
                      assessment.pageOneDTO.instrumentConfigurations
                        .instrumentConfigurations[0].isoCal === "Yes"
                        ? "isoCal active, perform when prompted"
                        : "-",
                  },
                ],
              },
              {
                name: "secondSection",
                values: [
                  {
                    value:
                      assessment.pageFiveDTO.onePointCalibrationDTO
                        .recommendedFrequency,
                  },
                  {
                    value:
                      assessment.pageFiveDTO.onePointCalibrationDTO
                        .recommendedTestWeight,
                  },
                  {
                    value:
                      assessment.pageFiveDTO.onePointCalibrationDTO
                        .accuracyClassTestWeight,
                  },
                  {
                    value:
                      assessment.pageFiveDTO.onePointCalibrationDTO
                        .testNominalTestWeight,
                  },
                  {
                    value:
                      assessment.pageFiveDTO.onePointCalibrationDTO
                        .calibrationCycle,
                  },
                  {
                    value:
                      assessment.pageFiveDTO.onePointCalibrationDTO
                        .actionToleranceLimit,
                  },
                  {
                    value:
                      assessment.pageFiveDTO.onePointCalibrationDTO
                        .warningToleranceLimit,
                  },
                ],
              },
              {
                name: "thirdSection",
                values: [
                  {
                    value:
                      assessment.pageFiveDTO.repeatabilityCheckDTO
                        .recommendedFrequency,
                  },
                  {
                    value:
                      assessment.pageFiveDTO.repeatabilityCheckDTO
                        .recommendedTestWeight,
                  },
                  {
                    value:
                      assessment.pageFiveDTO.repeatabilityCheckDTO
                        .accuracyClassTestWeight,
                  },
                  {
                    value:
                      assessment.pageFiveDTO.repeatabilityCheckDTO
                        .calibrationCycle,
                  },
                  {
                    value:
                      assessment.pageFiveDTO.repeatabilityCheckDTO
                        .actionToleranceLimit,
                  },
                  {
                    value:
                      assessment.pageFiveDTO.repeatabilityCheckDTO
                        .warningToleranceLimit,
                  },
                ],
              },
            ]}
          />
        </>
      ) : (
        <CSpinner></CSpinner>
      )}
    </>
  );
};

export default Step4;
