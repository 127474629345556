import "./index.css";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { NavigationEvent } from "./types/tagManagerEvents";
import LanguageUtils from "./utils/LanguageUtils";
import TagManager from "react-gtm-module";
import env from "@beam-australia/react-env";

declare global {
  interface Window {
    dataLayer: (Gtag.EventParams | NavigationEvent)[];
  }
}

const tagManagerArgs = {
  gtmId: env("GTM_ID"),
};

LanguageUtils.initI18n();
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <Router basename="/Service-Finder-Tool">
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
